
(function (oneteam, $) {
    oneteam.main = (function() {
        return {
            initialize: function() {
                this.switchTabContent();
            },

            switchTabContent: function() {
                function tabList(param) {
                    $(`.nt-index .section-2 ul.nav-tabs.${param}-tab  li`).each(function() {
                        $(this).on('click', function() {
                            var tabReferenceId = $(this).children('a').attr('href').replace("#", "");

                            $(`.nt-index .section-2 ul.nav-tabs.${param}-tab li a`).removeClass("active");
                            $(this).children('a').addClass("active");

                            toggleTabContent(tabReferenceId);
                        });
                    });

                    function toggleTabContent(refId) {
                        $(`.nt-index .section-2 .tab-content.${param}-tabcontent .tab-pane`).each(function() {
                            var tabContentId = $(this).attr('id');
                            $(this).removeClass('fade active show');
                            if ( refId === tabContentId) {
                                $(this).addClass("fade active show");
                            }
                        });
                    }
                }

                tabList("lg-features");
                if ($(window).width() <= 992) {
                    tabList("xs-communication");
                    tabList("xs-colaboration");
                }
            }
        }
    })();

    oneteam.loadWhen(oneteam.main, function($, window) {
        var path = window.stripWindowsLocationPath();
        return path === "/" || path === "/home";
    });

})(oneteam, jQuery);

