
(function (oneteam, $) {
    oneteam.owlCarousalSlider = (function() {
        return {
            initialize: function() {
                this.indexPageDeviceSlider();
            },

            indexPageDeviceSlider: function() {
                $('.owl-carousel.devices').owlCarousel({
                    stagePadding: 100,
                    loop:false,
                    margin:30,
                    nav:false,
                    dots: true,
                    lazyLoad: true,
                    slideTransition: `ease-out`,
                    responsive:{
                        0:{
                            items:1
                        },
                        600:{
                            items:4
                        },
                        1000:{
                            items:4
                        }
                    }
                });
            }
        }
    })();

    oneteam.loadWhen(oneteam.owlCarousalSlider, function($, window) {
        var path = window.stripWindowsLocationPath();
        return path === "/" || path === "/download" || path === "/push-to-talk-app"
    });

})(oneteam, jQuery);

