(function (oneteam, $) {
    oneteam.navbar = (function () {

        return {
            /**
             * Initializes the landing page components
             * @return nothing
             */
            initialize: function () {
                this.navbarToggler();
            },

            navbarToggler: function() {
                if($(window).width() <= 1024){
                    $('.navbar .navbar-toggler').on('click', function() {
                        $('.navbar-collapse').toggleClass('slide-down');
                    });
                }
            }
        }
    })();

    oneteam.loadWhen(oneteam.navbar, function($, window) {
        var path = window.stripWindowsLocationPath();
        return window;
    });

})(oneteam, jQuery);