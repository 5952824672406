(function ($) {
    $(function () {

        $(document).ready(function () {
            var hubspotPostUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/5158683/2f48c171-565f-4dcd-8dea-b1e02c5d4483'
            var siteKey = '6LeH6asZAAAAAB2ns1qXYoV4swQ34EOkpHRR7Bvc'
            var signupApi = $('#signup-api-end-point');
            var userNameError = $('#user_name-error')
            var userLastNameError = $('#user_last_name-error')
            var emailError = $('#user_email-error')
            var termsError = $('#terms-error')
            var orgNameError = $('#org_name-error')
            var passwordError = $('#password-error')
            var confirmPasswordError = $('#confirm_password-error')
            var phoneError = $("#phone-error")
            var userNameInput = $('#user_name')
            var userLastNameInput = $('#user_last_name')
            var emailInput = $('#user_email')
            var orgNameInput = $('#org_name')
            var passwordInput = $('#password')
            var phoneInput = $('#phone')
            var phoneFieldEmptyLabel = $('#phone-field-empty-error')
            var confirmPasswordInput = $('#confirm_password')
            var termsCheckbox = $('#accept_tnc')
            var responseError = $('#response-error')
            var alertDanger = $('.alert-danger')
            var alertInfo = $('.alert-info')
            var errorContainer = $('#error-container')
            var infoContainer = $('#info-container')
            var dismissErrorButton = $('#dismiss-error')
            var dismissInfoButton = $('#dismiss-info')
            var intelInputField = document.querySelector("#phone")
            var registerUserButton = $('#register-user')
            var form = $('#new_user')
            const DEFAULT_PHONE_ERROR = "Please enter valid phone number"
            var iti = intlTelInput(intelInputField, {
                customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                    return 'Phone number';
                },
                // allowDropdown: false,
                // autoHideDialCode: false,
                // autoPlaceholder: "off",
                // dropdownContainer: document.body,
                // excludeCountries: ["us"],
                // formatOnDisplay: false,
                // geoIpLookup: function(callback) {
                //   $.get("http://ipinfo.io", function() {}, "jsonp").always(function(resp) {
                //     var countryCode = (resp && resp.country) ? resp.country : "";
                //     callback(countryCode);
                //   });
                // },
                geoIpLookup: function (callback) {
                    $.get("https://ipinfo.io", function () {
                    }, "jsonp").always(function (resp) {
                        // Putting By deafult value to india because India will be shown by default
                        var countryCode = (resp && resp.country) ? resp.country : "in";
                        callback(countryCode);
                    });
                },
                // hiddenInput: "full_number",
                initialCountry: "auto",
                separateDialCode: true,
                // localizedCountries: { 'de': 'Deutschland' },
                nationalMode: true,
                // onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
                // placeholderNumberType: "MOBILE",
                preferredCountries: ["in", "us", "gb", "fr", "sg", "au", "ca", "za", "mx", "th"],
                // separateDialCode: true,
                utilsScript: "./utils.js",
            });
            intelInputField.addEventListener("focus", function () {
                // result.textContent = "";
            }, false);

            // Name input field handler
            userNameInput.on('input', function (evt) {
                var value = $.trim(evt.target.value)
                userNameInput.val(value)
                value.length < 3 ? showNameError() : hideNameError()
            })

            // Last Name input field handler
            userLastNameInput.on('input', function (evt) {
                var value = $.trim(evt.target.value)
                userLastNameInput.val(value)
                value.length < 3 ? showLastNameError() : hideLastNameError()
            })

            // Email input field handler
            emailInput.on('input', function (evt) {
                var value = $.trim(evt.target.value)
                emailInput.val(value)
                value !== '' ? hideEmailError() : showEmailError()

                // Validate email id
                validateEmail(value)
            })

            // Org Name input field handler
            orgNameInput.on('input', function (evt) {
                var value = $.trim(evt.target.value)
                value.length != 0 && value.length < 2 ? showOrgNameError() : hideOrgNameError()
            })

            phoneInput.on('countrychange', function(e) {
                if (phoneInput[0].value) {
                    phoneInput[0].value = ''
                }
            });

            phoneInput.on('input', function (evt) {
                phoneFieldEmptyLabel.addClass('d-none')
                if (iti.isValidNumber() || phoneInput[0].value == '') {
                    phoneError[0].innerText = "";
                    phoneInput.removeClass("error");
                }
            })

            phoneInput.on('blur', function (evt) {
                var value = evt.target.value;
                if (!iti.isValidNumber() && value.length > 0) {
                    phoneError[0].innerText = DEFAULT_PHONE_ERROR;
                    phoneInput.addClass("error");
                }
            })

            // Password input field handler
            passwordInput.on('input', function (evt) {
                var value = $.trim(evt.target.value);
                value.length != 0 && value.length < 8 ? showPasswordError() : hidePasswordError()
            })

            // Password input field handler
            confirmPasswordInput.on('input', function (evt) {
                var value = $.trim(evt.target.value);
                (value.length != 0 && $.trim(passwordInput.val()) != value) ? showConfirmPasswordError() : hideConfirmPasswordError()
            })

            form.submit(function (event) {
                event.preventDefault();
                // Clear previous errors before sending request
                dismissError()
                // Clear previous alerts if user submits form again
                dismissInfo()
                // Add your logic to submit to your backend server here.
                if (isFormValid()) {
                    let that = this
                    grecaptcha.ready(function () {
                        grecaptcha.execute(siteKey, {action: 'contact'}).then(function (token) {
                            // form.prepend('<input type="hidden" name="token" value="' + token + '">');
                            var formData = $(that).serializeFormJSON(token);
                            submitForm(formData);
                            return false;
                        });
                    });
                }

            });


            $.fn.serializeFormJSON = function (token) {
                var o = {};
                var a = this.serializeArray();

                if (isPhoneEntered()) {
                    let phoneField = {
                        name: 'phone_number',
                        value: getContactNumber()
                    }

                    a.push(phoneField)
                }

                // Add captcha token
                let captchaToken = {
                    name: 'token',
                    value: token
                }

                a.push(captchaToken)

                $.each(a, function () {
                    if (o[this.name]) {
                        if (!o[this.name].push) {
                            o[this.name] = [o[this.name]];
                        }
                        o[this.name].push(this.value || '');
                    } else {
                        o[this.name] = this.value || '';
                    }
                });
                return o;
            };

            function submitForm(formData) {
                // disable button while server request is in progress
                disableSubmitButton()
                var signupApiEndPoint = signupApi.text().trim() + "/api/v1/signup/user";
                delete formData.confirm_password
                var jsonFormData = JSON.stringify(formData);
                $.ajax({
                    type: "POST",
                    url: signupApiEndPoint,
                    cache: false,
                    contentType: "application/json; charset=utf-8",
                    data: jsonFormData,
                    dataType: "text",
                })
                    .done(function (response) {
                        // relative will path only work if html file has <base> tag with base url in it.
                        // Takes user to thank you page
                        sendToHubspot();
                    })
                    .then(function (response) {

                        console.log('response', response)
                    })
                    .fail(function (jqXHR, textStatus) {
                        enableSubmitButton()
                        // If request conflict with current state of the server
                        // more likely email already esists
                        if (jqXHR.status === 409) emailAlreadyExists()

                        let {error_message} = JSON.parse(jqXHR.responseText)

                        if (textStatus == 'error' && jqXHR.status !== 409) {
                            // TODO: reset this flow
                            errorContainer.removeClass('display-none')
                            alertDanger.removeClass('fade')
                            responseError.html(error_message)
                        }
                    });
            }

            function sendToHubspot() {
                var body = formSubmissionData();
                $.ajax({
                    url: hubspotPostUrl,
                    type: "POST",
                    dataType: 'json',
                    contentType: "application/json",
                    data: JSON.stringify(body),
                    success: function (response) {
                        console.log(response);
                    },
                    error: function (error) {
                        console.log(error);
                        // Enable submit button after server request is complete
                        enableSubmitButton();
                        window.location.href = '/thank_you';
                    }
                }).then(function () {
                    // Enable submit button after server request is complete
                    enableSubmitButton();
                    window.location.href = '/thank_you';
                })
            }

            function getCookieValue(cookieName) {
                var b = document.cookie.match('(^|[^;]+)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
                return b ? b.pop() : '';
            }

            function formSubmissionData() {
                let userNameValue = $.trim(userNameInput.val())
                let phoneValue = $.trim(phoneInput.val())
                let userLastNameValue = $.trim(userLastNameInput.val())
                let emailValue = $.trim(emailInput.val())
                var body = {
                    "submittedAt": Date.now(),
                    "fields": [
                        {
                            "name": "email",
                            "value": emailValue
                        },
                        {
                            "name": "lifecyclestage",
                            "value": "marketingqualifiedlead"
                        },
                        {
                            "name": "firstname",
                            "value": userNameValue
                        },
                        {
                            "name": "lastname",
                            "value": userLastNameValue
                        },
                        {
                            "name": "phone",
                            "value": phoneValue
                        }

                    ],
                    "context": {
                        "hutk": getCookieValue('hubspotutk'),
                        "pageUri": window.location.href,
                        "pageName": "Sign Up"
                    },
                    "legalConsentOptions": {
                        "consent": {
                            "consentToProcess": true,
                            "text": "I agree to allow NuovoTeam to store and process my personal data."
                        }
                    }
                };
                return body;
            }

            // Show a link to login if user email already exists
            function emailAlreadyExists() {
                infoContainer.removeClass('display-none')
                alertInfo.removeClass('fade')
            }

            function disableSubmitButton() {
                registerUserButton.addClass('disabled-button')
                registerUserButton.prop('disabled', true);
            }

            function enableSubmitButton() {
                registerUserButton.removeClass('disabled-button')
                registerUserButton.prop('disabled', false);
            }

            /**
             * dismiss error alert on form
             */
            function dismissError() {
                errorContainer.addClass('display-none')
                alertDanger.addClass('fade')
                responseError.html('')
            }

            function dismissInfo() {
                infoContainer.addClass('display-none')
                alertInfo.addClass('fade')
            }

            // Terms checkbox
            termsCheckbox.change(function () {
                $(this).is(':checked') ? hideTermsError() : showTermsError()
            })

            // Shows error if mail id is invalid
            function validateEmail(value) {
                if (!isEmailValid(value)) {
                    showEmailError()
                }
            }

            // Error dismiss button handler
            dismissErrorButton.click(function () {
                dismissError()
            });

            dismissInfoButton.click(function () {
                dismissInfo()
            });

            /**
             * Validates form fields
             * Name
             * Email
             */
            function isFormValid() {
                let isValid = true
                let userNameValue = $.trim(userNameInput.val())
                let userLastNameValue = $.trim(userLastNameInput.val())
                let orgNameValue = $.trim(orgNameInput.val())
                let passwordValue = $.trim(passwordInput.val())
                let confirmPasswordValue = $.trim(confirmPasswordInput.val())
                let emailValue = $.trim(emailInput.val())
                let phoneValue = $.trim(phoneInput.val())
                let termsAccepted = termsCheckbox.is(':checked')

                if (userNameValue.length < 3) {
                    isValid = false
                    userNameInput.val(userNameValue)
                    showNameError()
                }
                if (userLastNameValue < 3) {
                    isValid = false
                    userLastNameInput.val(userLastNameValue)
                    showLastNameError()
                }

                if (orgNameValue != 0 && orgNameValue < 2) {
                    isValid = false
                    orgNameInput.val(orgNameValue)
                    showOrgNameError();
                }

                if (!iti.isValidNumber()) {
                    isValid = false
                    phoneInput.val(phoneValue)
                    if (phoneValue <= 0) {
                        phoneError[0].value = ''
                        phoneFieldEmptyLabel.removeClass('d-none')
                        phoneInput.addClass("error")
                    }
                }

                if (passwordValue == "" || passwordValue.length < 8) {
                    isValid = false
                    passwordInput.val(passwordValue)
                    showPasswordError();
                }

                if (passwordValue == "" || passwordValue != confirmPasswordValue) {
                    isValid = false
                    confirmPasswordInput.val(confirmPasswordValue)
                    showConfirmPasswordError();
                }

                if (emailValue == '') {
                    isValid = false
                    emailInput.val(emailValue)
                    showEmailError()
                }
                if (!isEmailValid(emailValue)) {
                    emailInput.val(emailValue)
                    isValid = false
                    showEmailError()
                }

                if (termsAccepted == false) {
                    isValid = false
                    showTermsError()
                }
                return isValid
            }

            function showNameError() {
                userNameError.attr("style", "display: block")
                userNameInput.removeClass("valid");
                userNameInput.addClass("error");
            }

            function hideNameError() {
                userNameError.removeAttr("style", "display: block")
                userNameInput.removeClass("error");
                userNameInput.addClass("valid");
            }

            function showLastNameError() {
                userLastNameError.attr("style", "display: block")
                userLastNameInput.removeClass("valid");
                userLastNameInput.addClass("error");
            }

            function hideLastNameError() {
                userLastNameError.removeAttr("style", "display: block")
                userLastNameInput.removeClass("error");
                userLastNameInput.addClass("valid");
            }

            function showOrgNameError() {
                orgNameError.attr("style", "display: block")
                orgNameInput.removeClass("valid");
                orgNameInput.addClass("error");
            }

            function hideOrgNameError() {
                orgNameError.removeAttr("style", "display: block")
                orgNameInput.removeClass("error");
                orgNameInput.addClass("valid");
            }

            function showPasswordError() {
                passwordError.attr("style", "display: block")
                passwordInput.removeClass("valid");
                passwordInput.addClass("error");
            }

            function hidePasswordError() {
                passwordError.removeAttr("style", "display: block")
                passwordInput.removeClass("error");
                passwordInput.addClass("valid");
            }

            function showConfirmPasswordError() {
                confirmPasswordError.attr("style", "display: block")
                confirmPasswordInput.removeClass("valid");
                confirmPasswordInput.addClass("error");
            }

            function hideConfirmPasswordError() {
                confirmPasswordError.removeAttr("style", "display: block")
                confirmPasswordInput.removeClass("error");
                confirmPasswordInput.addClass("valid");
            }

            function showEmailError() {
                emailError.attr("style", "display: block")
                emailInput.removeClass("valid");
                emailInput.addClass("error");
            }

            function hideEmailError() {
                emailError.removeAttr("style", "display: block")
                emailInput.removeClass("error");
                emailInput.addClass("valid");
            }

            function showTermsError() {
                termsError.attr("style", "display: block")
            }

            function hideTermsError() {
                termsError.removeAttr("style", "display: block")
            }

            function isPhoneEntered() {
                return intelInputField.value.length > 0
            }

            function getContactNumber() {
                var country = iti.getSelectedCountryData()
                return country.dialCode + "-" + intelInputField.value
            }

            /**
             * @returns {boolean} weather mail id is valid or not
             * @param {*} email mail id to validate
             */
            function isEmailValid(email) {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                let isValidEmail = re.test(String(email).toLowerCase());
                return isValidEmail;
            }
        })
    })
}(jQuery))
