const { data } = require("jquery");

(function(oneteam, $) {

    oneteam.featuresOverview = (function() {
        return {
            /**
             * Initializes the landing page components
             * @return nothing
             */
            initialize: function() {
                this.scrollNtFeatures();
            },

            scrollNtFeatures: function() {
                var sideNavLinks = $(".nt-features-wrapper ul.side-nav li a");

                var sideNavMenu = $(".nt-features-wrapper ul.side-nav");

                sideNavLinks.each(function() {
                    $(this).on("click", function() {
                        var $href = $(this).attr('href');
                        var $anchor = $($href).offset();
                        /*section scroll*/
                        $('html, body').animate({
                            scrollTop: $anchor.top - 100
                        }, 50);
                        return false;
                    });
                });

                $(window).on("scroll", function() {
                    var windowScroll = $(window).scrollTop();
                    if (windowScroll >= 466) {
                        $('.nt-features-wrapper .section').each(function(i) {
                            if ($(this).position().top <= windowScroll - 450) {
                                if($(this).attr('id') === "what_benefits_ptt"){
                                    sideNavMenu.css({top: '-100px'})
                                }
                                else if($(this).attr('id') === "what_ptt_Apps"){
                                    sideNavMenu.css({top: '150px'})
                                }
                                $('.nt-features-wrapper ul.side-nav li a.active').removeClass('active');
                                $('.nt-features-wrapper ul.side-nav li a').eq(i).addClass('active');
                            }
                        });
                    } 
                    else {
                        $('.nt-features-wrapper ul.side-nav li a.active').removeClass('active');
                        $('.nt-features-wrapper ul.side-nav li a:first').addClass('active');
                    }
                });

                // faqs page chev rotate animation
                $("#faq-que .faq-container .panel-group .panel a").on('click', function() {
                    setTimeout(function() {
                        $("#faq-que .faq-container .panel .panel-collapse").each(function() {
                            if ($(this).hasClass('show') === true) {
                                $(this).parent().find('.zmdi').addClass('rotate-chev');
                            }
                            else if (!($(this).hasClass('show'))){
                                $(this).parent().find('.zmdi').removeClass('rotate-chev').css('transition', '0.3s linear');
                            }
                        });
                    }, 380);
                
                });
            }
        };
    })();

    oneteam.loadWhen(oneteam.featuresOverview, function($, window) {

        var path = window.stripWindowsLocationPath();

        return path == "/features-overview" || "/what-is-push-to-talk-ptt" || "/walkie-talkie-app";
    });
})(oneteam, jQuery);