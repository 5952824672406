/**
 * Bootstrapping the JS code
 */

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
      value: function(predicate) {
       // 1. Let O be ? ToObject(this value).
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }

        var o = Object(this);

        // 2. Let len be ? ToLength(? Get(O, "length")).
        var len = o.length >>> 0;

        // 3. If IsCallable(predicate) is false, throw a TypeError exception.
        if (typeof predicate !== 'function') {
          throw new TypeError('predicate must be a function');
        }

        // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
        var thisArg = arguments[1];

        // 5. Let k be 0.
        var k = 0;

        // 6. Repeat, while k < len
        while (k < len) {
          // a. Let Pk be ! ToString(k).
          // b. Let kValue be ? Get(O, Pk).
          // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
          // d. If testResult is true, return kValue.
          var kValue = o[k];
          if (predicate.call(thisArg, kValue, k, o)) {
            return kValue;
          }
          // e. Increase k by 1.
          k++;
        }

        // 7. Return undefined.
        return undefined;
      }
    });
  }

  // define our global namespace here
  window.oneteam = {};

  // stores the modules (internal)
  oneteam.__modules = [];
  oneteam.__domReady = false;



  /**
   * Utility method to register a module along with the condition function, the condition
   * function is invoked to determine whether to initialize module when the DOMContentLoaded
   * event is fired by the browser.
   *
   * The `module` object must have an `initialize` method, the initialize method receives
   * the following arguments:
   * - Document
   * - jQuery instance
   *
   * The `conditionFn` should return a Boolean value to indicate whether the module is to be
   * initialized when the DOMContentLoaded even is fired. It receives the following arguments:
   * - jQuery instance
   * - Window
   *
   * @param  {Object} module        The module to initialize
   * @param  {Function} conditionFn The function that determines whether the module should be initialized
   * @return {void}
   */
  oneteam.loadWhen = function (module, conditionFn) {
      // if dom is ready then we immediately invoke the condition and try to initialize
      // the module
      if (oneteam.__domReady) {
          if (conditionFn($, window)) {
              try {
                  module.initialize(document, $);
              } catch (e) {
                  console.log('Method missing: initialize()');
              }
          }
      } else {
          // store it for future use
          if (module !== undefined && conditionFn !== undefined) {
              oneteam.__modules.push([module, conditionFn]);
          }
      }
  }

  /**
   * Boots the application by initializing the registered modules.
   * @return void
   */
  oneteam.bootApplication = function () {

      var
          modules = oneteam.__modules,
          i, module, pair;

      for (i = 0; i < modules.length; ++i) {
          module = modules[i][0];
          condition = modules[i][1];

          if (condition($, window)) {
              try {
                  console.log('Initializing Module: ', module);
                  module.initialize(document, $);
              } catch (e) {
                  console.error('Method missing: initialize()', e);
              }
          }
      }
  }



  // attach to DOM ready
  $(document).ready(function () {
      // dom is loaded
      oneteam.__domReady = true;

      oneteam.bootApplication();
  });
