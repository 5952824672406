const animateTextElement = document.querySelector("#type-text-animation");

const typeTextAnimation = (text, i) => {
    // check if text isn't finished yet
    if (i < (text.length)) {
        animateTextElement.innerHTML =
        text.substring(0, i+1);

        // wait for a while and call this function again for next character
        setTimeout(function() {
            typeTextAnimation(text, i + 1)
        }, 100);
    } else {
        // overlays a text layer over element. make sure to set same text as content for psuedo element in css
        animateTextElement.classList.add('overlay')
    }
}

/**
    @param {String} pageUrlPath page url path on which animation is to be displayed
    @param {String} text animation text
*/
const loadAnimation = (pageUrlPath, text) => {
    if (window.location.pathname === pageUrlPath) {
        typeTextAnimation(text, 0)
    }
}

// load animation for /alternative-to-zello-ptt page
loadAnimation("/alternative-to-zello-ptt", "Say Hello to NuovoTeam.")
loadAnimation("/alternative-to-voxer-ptt", "Say Hello to NuovoTeam.")
