
(function (oneteam, $) {
    oneteam.genericEvents = (function() {
        return {
            initialize: function() {
                let blazy = new Blazy();
            },
        }
    })();

    oneteam.loadWhen(oneteam.genericEvents, function($, window) {
        return true;
    });

})(oneteam, jQuery);

