import Lottie from "lottie-web";

const pttDeviceAnimationConfig = {
    container: document.getElementById('ptt-device-animation'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: '/json/ptt-animation/ptt-device-animation.json'
}
const pttDeviceAnimation = new Lottie.loadAnimation(pttDeviceAnimationConfig)

// play animation once DOM content are loaded
$(window).on('load resize', () => {
    if (window.outerWidth > 1199) {
        pttDeviceAnimation.play()
    } else {
        pttDeviceAnimation.stop()
    }
})


